import {Controller} from "@hotwired/stimulus"
import axios from "axios";
import getCSRFToken from "@shopify/csrf-token-fetcher";

export default class extends Controller {

    connect() {

        // initialize top-right-buttons

        const save_btn = document.getElementById('save-project-button')
        save_btn.addEventListener('click', () => this.save_form())

        const cancel_btn = document.getElementById('cancel-project-form-button')
        cancel_btn.addEventListener('click', () => this.cancel_form())

        const publish_btn = document.getElementById('publish-button')
        publish_btn.addEventListener('click', () => this.toggle_publication_button(publish_btn))
        publish_btn.addEventListener('mouseenter', function () {
            const form = document.getElementById('project-form')
            if (form.classList.contains('dirty') || document.querySelector('.project-publication-status.published')) {
                publish_btn.classList.remove('clickable')
                publish_btn.classList.add('not-clickable')
                console.log('remove class')
            } else {
                publish_btn.classList.add('clickable')
                publish_btn.classList.remove('not-clickable')
                console.log('add class')
            }
        })

        // project data status
        const ds_input = document.getElementById('project_data_status_data_new_entry')
        ds_input.addEventListener('change', () => this.toggle_project_data_status())
        const ds_input2 = document.getElementById('project_data_status_data_after_completion')
        ds_input2.addEventListener('change', () => this.toggle_project_data_status())
        //this.toggle_project_data_status()
    }

    save_form() {
        const btn = this.element.querySelector('#project-form input[type="submit"]')
        btn.click()
    }

    cancel_form() {
        const btn = this.element.querySelector('#project-form .cancel-form-button')
        btn.click()
    }

    toggle_publication_button() {
        const form = this.element.querySelector('#project-form')
        if (form.classList.contains('dirty')) {
            alert('Ungespeicherte Änderung, Publizieren nicht möglich')
        } else if (document.querySelector('.project-publication-status.published')) {
            alert('Das Projekt ist bereits publiziert')
        } else {
            // execute
            if (confirm('Publizieren .. kann nicht rückgängig gemacht werden')) {

                const id = this.element.getAttribute('data-project-id')
                axios.patch(`/projects/${id}/publish`, {authenticity_token: getCSRFToken()})
                    .then(function (response) {

                        if (response.status === 200) {

                            const marker = document.querySelector('.base-data .project-publication-status')
                            marker.classList.remove('new_no_content')
                            marker.classList.add('published')

                        } else {
                            alert('FEHLER: Projekt konnte nicht publiziert werden.')
                        }
                    })
            }
        }
    }

    toggle_project_data_status() {
        const wrapper = document.getElementsByClassName('project-data-status')[0]

        const ds_input = wrapper.querySelector('input#project_data_status_data_new_entry')

        const sel1 = wrapper.querySelector('select#project_data_status_new_progress')
        const sel2 = wrapper.querySelector('select#project_data_status_after_completion_progress')

        if (ds_input.checked) {
            sel1.removeAttribute('disabled')
            sel2.setAttribute('disabled', 'disabled')
        } else {
            sel1.setAttribute('disabled', 'disabled')
            sel2.removeAttribute('disabled')
        }

    }

}